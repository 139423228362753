<template>
  <b-container class="vehicle-details px-md-6">
    <h2 class="text-primary title text-center">{{ $t("ListingProcess.Review") }}</h2>

    <div class="mt-4">
      <TheListingCard />
    </div>

    <b-row class="mt-4">
      <b-col cols="12">
        <h5 class="section-title">{{ $t("ListingProcess.vehicleDetails") }}</h5>
      </b-col>
      <b-col cols="12" md="6">
        <table class="table details-table">
          <tr>
            <td>
              <p>{{ $t("ListingProcess.OdometerReading") }}:</p>
            </td>
            <td class="bold">
              {{ numberFormatter($store.getters.vehicleMileage) }}
            </td>
            <td>
              <b-button
                class="border-0 bg-transparent text-primary mini-font p-0"
                @click="
                  openWindow(
                    'Odometer reading',
                    'vehicleMileage',
                    $store.getters.vehicleMileage
                  );
                  inputType = 1;
                  inputID = 11;
                  changeOninput();
                "
                ><b-icon icon="pencil-square" scale="0.7"></b-icon
                >{{ $t("global.button.edit") }}
              </b-button>
            </td>
          </tr>
          <tr>
            <td><p>Number of key:</p></td>
            <td class="bold">
              {{ $store.getters.vehicleNumberOfKeys }}
            </td>
            <td>
              <b-button
                class="border-0 bg-transparent text-primary mini-font p-0"
                @click="
                  openSelectWindow('Number of keys', 'vehicleNumberOfKeys', keys);
                  inputID = 13;
                "
                ><b-icon icon="pencil-square" scale="0.7"></b-icon
                >{{ $t("global.button.edit") }}
              </b-button>
            </td>
          </tr>
          <tr v-show="!$store.getters.hasDoorInfo">
            <td><p>Number of doors:</p></td>
            <td class="bold">
              {{ $store.getters.vehicleDoors }}
            </td>
            <td>
              <b-button
                class="border-0 bg-transparent text-primary mini-font p-0"
                @click="
                  openWindow(
                    'Number of doors',
                    'vehicleDoors',
                    $store.getters.vehicleDoors
                  );
                  inputType = 2;
                  inputID = 6;
                  changeOninput();
                "
                ><b-icon icon="pencil-square" scale="0.7"></b-icon
                >{{ $t("global.button.edit") }}
              </b-button>
            </td>
          </tr>
          <tr v-show="!$store.getters.hasSeatInfo">
            <td><p>Number of doors:</p></td>
            <td class="bold">
              {{ $store.getters.vehicleDoors }}
            </td>
            <td>
              <b-button
                class="border-0 bg-transparent text-primary mini-font p-0"
                @click="
                  openWindow(
                    'Number of seats',
                    'vehicleSeats',
                    $store.getters.vehicleSeats
                  );
                  inputType = 2;
                  inputID = 7;
                  changeOninput();
                "
                ><b-icon icon="pencil-square" scale="0.7"></b-icon
                >{{ $t("global.button.edit") }}
              </b-button>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row
      ><b-col>
        <b-link v-b-modal.fullFeatures class="see-all">
          {{ $t("ListingProcess.SeeFullVehicleFeatures") }}
        </b-link>
      </b-col>
    </b-row>

    <b-row class="photos">
      <b-col cols="12">
        <h5 class="section-title">
          {{ $t("ListingProcess.photos") }}
        </h5>
      </b-col>
      <b-col cols="12">
        <TheImagesDelete />
        <TheUpload />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <h5 class="section-title">
          {{ $t("ListingProcess.AskingPrice") }}
          <b-icon icon="exclamation-circle" scale="0.7"></b-icon>
        </h5>
      </b-col>
      <b-col cols="12" class="mt-2">
        <h6 class="mb-0">
          {{ $t("ListingProcess.AskingPrice") }}:
          <b>{{ currencyFormatter($store.getters.vehiclePrice) }}</b>

          <b-button
            class="border-0 bg-transparent text-primary mini-font"
            @click="
              openWindow('Asking Price', 'vehiclePrice');
              inputType = 3;
            "
            ><b-icon icon="pencil-square" scale="0.7"></b-icon
            >{{ $t("global.button.edit") }}
          </b-button>
        </h6>
      </b-col>
      <b-col cols="12">
        <b-alert show dismissible class="alert-box info-box border-0 py-5 price-alert">
          <span>
            {{ $t("ListingProcess.priceEnterWarn") }}
          </span>
        </b-alert>
      </b-col>
    </b-row>

    <TheLocationEditer ref="LocationEditor" />

    <b-modal id="modifyValue" :title="modalTitle" hide-footer centered>
      <div class="p-5 text-center">
        <b-input-group class="m-auto" style="max-width: 300px" v-if="inputType === 3">
          <b-input-group-prepend class="border left-rounded">
            <country-flag
              style="max-height: 36px"
              :country="$store.getters.country.Code"
            />
            <span class="my-auto mr-2">{{ $store.getters.country.Currency }}</span>
          </b-input-group-prepend>

          <b-form-input
            type="number"
            min="0"
            class="right-rounded"
            v-model="inputValue"
            oninput="this.value = Math.abs(this.value); if(this.value === 0) this.value = null;"
          />
        </b-input-group>

        <b-input-group class="mx-auto pl-3" style="max-width: 300px" v-else>
          <b-form-input
            type="number"
            min="1"
            max="9"
            class="button-rounded"
            v-model="inputValue"
            :oninput="oninput"
          />
        </b-input-group>
        <b-form-radio-group
          v-show="inputType === 1"
          v-model="odometer"
          @change="changeUnit()"
          class="py-2"
        >
          <b-form-radio value="km">Kilometers</b-form-radio>
          <b-form-radio value="mi">Miles</b-form-radio>
        </b-form-radio-group>
        <b-row class="mt-3">
          <b-col>
            <b-btn
              variant="recommend"
              pill
              size="sm"
              style="width: 100px"
              @click="$bvModal.hide('modifyValue')"
            >
              {{ $t("global.button.cancel") }}
            </b-btn>
          </b-col>
          <b-col>
            <b-btn
              variant="primary"
              pill
              size="sm"
              style="width: 100px"
              @click="
                saveInput(storeName, inputValue);
                setListing(inputID, inputValue);
                $bvModal.hide('modifyValue');
              "
            >
              {{ $t("global.button.update") }}
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal id="selectValues" :title="modalTitle" hide-footer centered>
      <div class="p-5 text-center">
        <div class="pb-4">
          <b-form-checkbox
            v-model="key"
            button
            v-for="l in selectList"
            :key="l.Order"
            :value="l.Value"
            class="mx-1"
            button-variant="outline-primary"
            @change="
              saveInput(storeName, l.Value);
              inputValue = l.TagValueId;
            "
          >
            <span class="px-2">{{ l.Value }}</span>
          </b-form-checkbox>
        </div>

        <b-row class="mt-3">
          <b-col>
            <b-btn
              variant="recommend"
              pill
              size="sm"
              style="width: 100px"
              @click="$bvModal.hide('selectValues')"
            >
              {{ $t("global.button.cancel") }}
            </b-btn>
          </b-col>
          <b-col>
            <b-btn
              variant="primary"
              pill
              size="sm"
              style="width: 100px"
              @click="
                setListing(inputID, inputValue);
                $bvModal.hide('selectValues');
              "
            >
              {{ $t("global.button.update") }}
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal id="fullFeatures" size="lg" scrollable hide-footer>
      <template #modal-title>
        <h3 class="modal-title">{{ $t("Overview.AllFeatures") }}</h3></template
      >
      <div class="modal-carname">
        <h3 class="car-make">
          {{ vehicleName }}
        </h3>
        <h3 class="car-mode">
          {{ vehicleTrim }}
        </h3>
      </div>

      <div class="modal-all-features">
        <div v-for="(sf, key) in allOptions" :key="key">
          <template v-if="sf.Tags && sf.Tags.length > 0 && sf.Tags.some(o => o.TagValues[0] && o.TagValues[0].Installed === true)">
            <h4
              class="pb-2 feature-part-title"
              v-show="countContents(sf.Tags, sf.TagCategories) > 0"
            >
              {{ sf.Name }}
            </h4>
            <ul>
              <template
                v-for="(sft, key) in sf.Tags.filter(
                  (item) => item.TagValues[0] && item.TagValues[0].Installed === true
                )"
              >
                <li
                  :key="key"
                  class="feature-item"
                  v-if="sft.TagValues[0].Installed"
                >
                  <span>
                    {{ sft.TagName }}
                  </span>
                </li>
              </template>
            </ul>
            <ul
              v-for="(sftc, key) in sf.TagCategories"
              :key="'sftc_' + key"
              class="feature-item"
            >
              <li
                v-for="(sftct, key) in sftc.Tags"
                :key="'sftct_' + key"
                class="feature-item"
              >
                <span
                  v-show="sftct.TagValues[0].Installed"
                  class="mb-1"
                >
                  {{ sftct.TagName }}
                </span>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </b-modal>

    <b-modal id="specifications" hide-header hide-footer centered size="lg">
      <div class="p-4">
        <h3 class="border-bottom mb-4">
          {{ $t("ListingProcess.VehicleSpecifications") }}
        </h3>
        <TheListingCard />
      </div>
    </b-modal>
    <b-alert
      variant="info"
      show
      dismissible
      class="alert-box info-box contact flex-column py-6 align-items-start mb-4"
      style="background:rgb(197 237 195) !important"
    >
      <h5>
        <b-avatar variant="success" size="1.4em">
          <b-icon-shield-check scale="0.8"/>
        </b-avatar>
        <b class="px-2">{{ $t("ListingProcess.ContactWarnTitle") }}</b>
      </h5>
      <p>
        {{ $t("ListingProcess.ContactWarnDesc") }}
      </p>
    </b-alert>

    <b-card-group class="py-7 rounded w-100 accept-box justify-content-center text-center" style="background-color:#CCE1F3;">
      <b-form-checkbox
        size="lg"
        v-model="accept1"
        :value="true"
        :unchecked-value="false"
        @change="checkNextButton()"
      >
        {{ $t("ListingProcess.termsLabel") }}
      </b-form-checkbox>
    </b-card-group>
    <!--  -->
    <div class="d-flex justify-content-between align-items-center mt-10">
      <div class="d-flex justify-content-between align-items-center">
        <b-button pill variant="half-dark" class="px-3" v-b-modal.cancelModal>
          <v-icon>mdi-close</v-icon> {{ $t("global.button.cancel") }}
        </b-button>
      </div>
        <TheSaveAndLeave />
      </div>
    <div v-if="$store.getters.listingPlanID === 1" class="middleBar_end"></div>
    <div v-else class="middleBar" />
  </b-container>

</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    TheListingCard: () => import("@/components/listing/TheListingCard"),
    TheImagesDelete: () => import("@/components/global/TheUploadImagesDelete"),
    TheUpload: () => import("@/components/global/TheUploadComponent"),
    TheLocationEditer: () => import("@/components/listing/TheLocationEditer"),
    TheSaveAndLeave: () => import("@/components/listing/TheSaveAndLeaveButton"),
  },
  data() {
    return {
      modalTitle: null,
      inputValue: null,
      oninput: null,
      selectList: null,
      inputType: null,
      inputID: null,
      storeName: null,
      accept1: false,
      accept2: false,
      odometer: this.$store.getters.mileageUnit,
      allOptions: [],
      keys: null,
      key: null,
      door: null,
      seat: null,
      contact: null
    };
  },
  props: {
    nextButton: { type: Function },
    toPage: { type: Function },
  },
  methods: {
    changeOninput() {
      if (this.inputType === 1) this.oninput = "this.value = Math.abs(this.value)";
      if (this.inputType === 2)
        this.oninput =
          "if(this.value < 1) this.value = 1; if(this.value > 9) this.value = null;";
    },
    openWindow(title, storeName, storeValue) {
      this.modalTitle = title;
      this.storeName = storeName;
      this.inputValue = storeValue;
      this.$bvModal.show("modifyValue");
    },
    openSelectWindow(title, storeName, arr) {
      this.modalTitle = title;
      this.selectList = arr;
      this.storeName = storeName;
      // this.inputType = id;
      arr.forEach((element) => {
        if (element.Installed) {
          // this.inputValue = element.TagValueId;
          this.inputValue = element.Value;
        }
      });
      this.$bvModal.show("selectValues");
    },
    saveInput(item, value) {
      this.$store.dispatch(item, value);
    },
    async setListing(tagId, value) {
      let v = value.toString();
      const tags = [
        {
          TagId: tagId,
          Value: v,
        },
      ];
      try {
        if (this.inputType === 3) {
          await axios.post("Listing/SetListing", {
            ListingId: this.$store.getters.listingStockID,
            Amount: v,
            CurrencyCode: this.$store.getters.currency,
          });
        } else {
          await axios.post("Listing/SetListing", {
            ListingId: this.$store.getters.listingStockID,
            Tags: tags,
          });
        }
        this.inputID = null;
      } catch (error) {
        console.log(error);
      }
    },
    changeUnit() {
      this.$store.dispatch("mileageUnit", this.odometer);
    },
    checkNextButton() {
      if (this.accept1) {
        this.nextButton("page6", false);
      } else {
        this.nextButton("page6", true);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 125);
    },
    async getVehicleDetails() {
      try {
        const response = await axios.post("Listing/CarInfo", {
          ListingId: this.$store.getters.listingStockID,
        });
        this.$refs.LocationEditor.autoFillUserInfo(response.data.Contact);
        this.$refs.LocationEditor.autoFilllocationInfo(response.data.Contact);
        response.data.TagCategories.forEach((element) => {
          if (element.Id === 1) {
            element.Tags.forEach((el) => {
              // if (el.TagId === 11) {
              //   el.TagValues.forEach(e => {
              //     if (e.Installed) {
              //       this.mileage = e.Value;
              //     }
              //   });
              // }
              if (el.TagId === 6) {
                this.doors = el.TagValues;
                el.TagValues.forEach((e) => {
                  if (e.Installed) {
                    this.door = e.Value;
                  }
                });
              }
              if (el.TagId === 7) {
                this.seats = el.TagValues;
                el.TagValues.forEach((e) => {
                  if (e.Installed) {
                    this.seat = e.Value;
                  }
                });
              }
            });
          }
          if (element.Id === 3) {
            element.Tags.forEach((el) => {
              if (el.TagId === 13) {
                this.keys = el.TagValues;
                el.TagValues.forEach((e) => {
                  if (e.Installed) {
                    this.key = e.Value;
                  }
                });
              }
            });
          }
          if (element.Id === 4) {
            this.allOptions = element.TagCategories;
          }
        });
      } catch (error) {
        console.log(error.data);
      }
    },
    numberFormatter(value) {
      return parseFloat(value ? value : 0).toLocaleString(
        this.$store.getters.currencyCulture,
        {
          style: "unit",
          unit: "kilometer",
        }
      );
    },
    currencyFormatter(value) {
      const sc = this.$store.getters.currencyForSeller;
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: sc ? sc : "CAD",
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    async ListingDone() {
      try {
        await axios.post("Listing/ListingDone", {
          ListingId: this.$store.getters.listingStockID,
        });
      } catch (error) {
        console.log(error.data);
      }
    },
    countContents(tags, tagCategories) {
      tags = tags.filter((el) => el.IsRequired && el.TagValues[0].Installed);
      return tags.length + tagCategories.length;
    },
  },
  computed: {
    ...mapGetters(["vehicleName", "vehicleTrim"]),
    vehicleContact() {
      let contact = {
        FirstName: this.$store.getters.vehicleContactFirstName,
        LastName: this.$store.getters.vehicleContactLastName,
        Email: this.$store.getters.vehicleContactEmail,
        Mobile: this.$store.getters.vehicleContactMobile,
      };
      return [contact];
    },
  },
  beforeMount() {
    this.checkNextButton();
    this.scrollToTop();
    this.getVehicleDetails();
  },
};
</script>

<style lang="scss">


.listingSubmit {
  position: relative;
  bottom: -94px;
  z-index: 3;
}
.photos {
  .primary-badge {
    bottom: -8px;
    right: 8px;
    left: auto !important;
    top: auto !important;
    width: 75px;
    height: 24px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.accept-box {
  label {
    font-size: 16px;
  }
  .custom-checkbox.b-custom-control-lg .custom-control-label::before,
  .custom-checkbox.b-custom-control-lg .custom-control-label::after {
    top: 2px;
  }
}
</style>

<style scoped lang="scss">
.cancelButton {
  margin-left: 210px;
}
.section-title {
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter", sans-serif !important;
  padding-bottom: 2px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 16px;
  text-transform: capitalize;
}
.details-table {
  font-size: 16px;
  margin-bottom: 0;
  p {
    margin-bottom: 0;
  }
  .bold {
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
  }
  td {
    border: none;
    padding: 0;
    text-align: left;
    width: min-content;
  }
  tr {
    &:first-child {
      td {
        padding-bottom: 16px;
      }
    }
  }
}
.see-all {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}
.photos {
  margin-top: 64px;
  margin-bottom: 64px;
}
.price-alert {
  margin-top: 32px;
  margin-bottom: 64px;
}
</style>

<style lang="scss">
#fullFeatures {
  .modal-header {
    background: $light-blue !important;
    height: 58px;
    position: relative;
  }
  .modal-body {
    padding: 0;
  }
  .modal-title {
    font-weight: 600;
    color: white;
    font-size: 20px;
    text-transform: capitalize;
  }
  .modal-content {
    border-radius: 4px;
    overflow: hidden;
    border: none;
  }
  .close {
    top: 15px;
    opacity: 1;
    color: white;
    padding: 0;
    font-size: 40px;
    position: absolute;
    right: 30px;
    height: 100%;
    padding-bottom: 10px;
    font-weight: normal;
  }
  .car-make {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 0;
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
  .car-mode {
    font-weight: normal;
    font-size: 24px;
    color: #404040;
    margin-bottom: 0;
    line-height: normal;
  }
  .modal-carname {
    border-bottom: 1px solid #cdcdcd;
    padding: 24px;
    @media (max-width: 575px) {
      padding: 16px;
    }
  }
  .modal-all-features {
    padding: 20px 24px;
    @media (max-width: 575px) {
      padding: 16px 16px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
    }
    li {
      width: 50%;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
  li.feature-item {
    min-height: auto;
    margin-bottom: 16px;
    position: relative;
    padding: 0 16px 0 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: normal;
    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #232323;
      left: 23px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.feature-part-title {
  font-size: 16px;
  font-weight: 500 !important;
  font-family: "Inter", sans-serif !important;
  color: #111;
  @media (max-width: 575px) {
    font-size: 18px !important;
  }
}
</style>
